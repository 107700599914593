<template>
  <div class="container dynamic-page">
    <!-- 搜索 -->
    <div class="search content">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-row>
          <el-col :span="14">
            <el-form-item>
              <el-select
                v-model="queryForm.termName"
                placeholder="学期"
                style="width: 188px"
                @change="handleChange"
              >
                <el-option
                  v-for="(item, index) in semesterlist"
                  :key="index"
                  :label="item.termName"
                  :value="item.termName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 动态 -->
    <div class="dynamic content" v-for="(item, index) in dynamic" :key="index">
      <div class="avatar">
        <el-avatar
          v-if="item.userInfo[0] && item.userInfo[0]!='null'"
          :src="imgSrc + item.userInfo[0]"
        ></el-avatar>
        <el-avatar
          v-else
          src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
        ></el-avatar>
        <div class="info">
          <h4>{{ item.userInfo[1] }}</h4>
          <p>{{ item.createTime.dateStr }} {{ item.createTime.time }}</p>
        </div>
      </div>
      <div class="article">
        <div class="art-cont" v-html="item.content"></div>
        <div class="art-image">
          <media-exhi v-if="item.imagesUrl" :file-data="item.imagesUrl"></media-exhi>
        </div>
        <div class="art-kind">
            <div class="kind kindC">
              {{item.indexOneName}} - {{ item.indexThreeName }}
            </div>
			<div class="operation">
				<el-link class="attdnce-stu" :underline="false" @click="handleStudent(item)"
				  >查看参与学生</el-link
				>
				<span class="padd"></span>
				<span
				  :class="{ dianzan: item.isDianZan !== '0' }"
				  @click="handleDianzan(item)"
				  ><i class="iconfont icon-dianzan"></i> {{ item.dianZanNum }}</span
				>
				<span class="padd">|</span>
				<span @click="openComment(item, index, {}, -1,  1)" ref="showInput">
					<i class="iconfont icon-tubiaozhizuomoban"></i>
				</span>
			</div>
        </div>
        <!-- 评论内容 -->
        <div class="discuss" v-if="item.comments.length > 0">
          <ul>
            <li v-for="(el, elin) in item.comments" :key="elin">
				<div class="avatar">
				  <el-image
					v-if="el[1] && el[1]!='null'"
					fit="cover"
					style="width: 40px;height: 40px;"
					:src="imgSrc + el[1]"
				  ></el-image>
				  <el-image
					v-else
					fit="cover"
					style="width: 40px;height: 40px;"
					src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
				  ></el-image>
				</div>
              <div class="info">
                <h4><span>{{el[2]}}</span>：{{el[4]}}</h4>
                <div style="display: flex; align-items: center">
                  <p>{{ el[3] ? el[3].dateStr : '' }} {{ el[3] ? el[3].time : '' }}</p>
                  <el-button v-if="is_self(el[0])"  size="mini" type="text" style="margin-left: 10px; color: #999999" @click="removeComment(item, index, el, elin, 1)">删除</el-button>
				  <el-button size="mini" type="text" style="margin-left: 10px; color: #999999"  ref="showInput" @click="openComment(item, index, el, elin, 2)">回复</el-button>
                </div>
				<!-- 2级评论 -->
				<div class="comments-2th" v-if="show2thCmtZone(el)">
					<ul>
					  <li v-for="(cmt, cmtIdx) in el[6].list" :key="cmtIdx">
						<div class="avatar">
							<el-image
							  v-if="cmt[1] && cmt[1]!='null'"
							  :src="imgSrc + cmt[1]"
							  fit="cover"
							  style="width: 40px;height: 40px;"
							></el-image>
							<el-image
							  v-else
							  src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
							  fit="cover"
							  style="width: 40px;height: 40px;"
							></el-image>
						</div>
					    <div class="info">
					      <h4>
					        <span>{{ cmt[2] }}</span
					        >：{{ cmt[4] }}
					      </h4>
					      <div style="display: flex; align-items: center">
					       <p>{{ cmt[3] ? cmt[3].dateStr : '' }} {{ cmt[3] ? cmt[3].time : '' }}</p>
					        <el-button v-if="is_self(cmt[0])"  size="mini" type="text" style="margin-left: 10px; color: #999999" @click="removeComment(item, index, el, elin, 2, cmt, cmtIdx)">删除</el-button>
							<el-button size="mini" type="text" style="margin-left: 10px; color: #999999" ref="showInput" @click="openComment(item, index, el, elin, 3, cmt, cmtIdx)">回复</el-button>
					      </div>
					    </div>
					  </li>
					</ul>
					<span class="see-more-cmts" @click="getMore2ndComments(item, index, el, elin)" v-if="showLoadMore2ndCmt(el)"><i class="el-icon-plus"></i>查看更多回复</span>
				</div>
              </div>
            </li>
          </ul>
		  <span class="see-more-cmts" @click="getMore1stComments(item, index)" v-if="showLoadMoreCmt(item, index)"><i class="el-icon-plus"></i>查看更多评论</span>
        </div>
        <!-- 打开评论 -->
        <div class="comment">
          <div class="comment-cont" ref="showInput" v-if="item.isComments">
            <div class="comment-text">
              <el-form
                :model="form"
                :rules="rules"
                ref="commentsForm"
                class="demo-ruleForm"
              >
                <el-form-item prop="commentContent">
                  <el-input
                    type="textarea"
                    :rows="3"
                    v-model="commentContent"
					:placeholder="commentPh"
                    resize="none"
                    v-focus
					:id="`cometEditor${index}`"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="comment-btn">
              <el-popover placement="left-end" v-model="showEmoji">
					<template>
						<ul class="emoji-list">
							<li v-for="(item,idx) in emojiList" :key="idx" @click="inputEmoji(idx, index)">{{ item.char }}</li>
						</ul>
					</template>
					<span slot="reference" class="iconfont icon-xiaolian" style="font-size: 20px;"></span>
              </el-popover>
              <div>
					<el-button type="primary" v-if="cmtMultiGrade.grade == 1" @click="submitComments(item, index)">评论</el-button>
					<el-button type="text" v-if="cmtMultiGrade.grade != 1" @click="openComment(item, index, {}, -1,  1)">切换至评论</el-button>
					<el-button type="success" v-if="cmtMultiGrade.grade != 1" @click="submitReply(item.id)">回复</el-button>
              </div>
            </div>
          </div>
          <div class="comment-none" @click="openComment(item, index, {}, -1, 1)" v-else>
            评论
          </div>
        </div>
      </div>
    </div>

    <!-- 参与学生 -->
    <el-dialog title="参与学生" :visible.sync="open" width="33%" center>
      <div class="dialog-name">
        <h3>学生名单（共{{studentList.length}}人）</h3>
        <ul class="name">
          <li v-for="item,index in studentList" :key="index">{{item}}</li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getIndex,
  getTeacherClass,
  getClassStudent,
  addGroupRecord,
  getGroupRecord,
  getDianzan,
  addComments,
  getComments,
  removeComments,
  reply2Cmt
} from "@/api/record/group/group";
import { getAllSemester } from "@/api/record/personal/personal"
import mediaExhi from "@/components/mediaExhibition.vue"
import { dynamicFn } from "@/mixin/dynamic/index.js"
import { dynamicStuGroup } from "@/mixin/dynamic/group/student.js"
export default {
	components: {
		mediaExhi
	},
	mixins: [ dynamicFn, dynamicStuGroup ],
  data() {
    return {
      queryForm: {
        pageSize: 15,
        pageNum: 1,
        deptId: "",
        termName: "",
      },
      pageTotal: 0,
      isComment: false,
      open: false,
      comment: "",
      semesterlist: [],
      dynamic: [],
      studentList: [],
      form: {},
      rules: {
        // commentContent: [
        //   { required: true, message: "请输入内容或表情", trigger: "blur" },
        // ],
      },
      commentContent: '',
	  commentPh: ''
    };
  },
  created() {
    this.semester()
  },
  mounted() {
    document.addEventListener("click", (e) => {
      // console.log(this.$refs.showInput)
      if (this.$refs.showInput) {
        let isSelf;
		let showArr = this.$refs.showInput
        for (let i = 0; i < showArr.length; i++) {
        	let v = showArr[i].$el ? showArr[i].$el : showArr[i]
			if (v.contains(e.target)) {
				isSelf = v.contains(e.target)
			}
        }
        if (!isSelf) {
          this.commentContent = ''
          this.dynamic.map((item) => {
            item.isComments = false;
          })
		  this.showEmoji = false
        }
      }
    });
    document.addEventListener("scroll",this.Scrollbottom)
  },
  methods: {
    getList() {
      getGroupRecord(this.queryForm).then((res) => {
        this.pageTotal = res.data.pages
        this.dynamic = res.data.list;
        this.dynamic.map(async (item, idx) => {
          item.createTime = this.splitDate(item.createTime);
          item.isComments = item.isComments == "0" ? false : true;
          item.userInfo = item.userInfo.split(",")
          if (item.studentName) {
            item.studentName = item.studentName.split(",").filter(Boolean)
          }
          item.comments = await this.process1stCommts(item, idx, [], [])
        })
      })
    },
    getListCount() {
		getGroupRecord(this.queryForm).then((res) => {
			let arr = res.data.list;
			arr.map(async (item, idx) => {
				item.createTime = this.splitDate(item.createTime);
				item.isComments = item.isComments == "0" ? false : true;
				item.userInfo = item.userInfo.split(",")
				if (item.studentName) {
					item.studentName = item.studentName.split(",").filter(Boolean)
				}
				item.comments = await this.process1stCommts(item, idx, arr, [])
				this.dynamic.push(item)
			})
		})
	},
    handleChange(termName) {
      this.queryForm.termName = termName;
      this.getList();
    },
    // 点赞
    handleDianzan(item) {
      getDianzan({ id: item.id }).then((res) => {
        if (item.isDianZan == "0") {
          item.isDianZan = "1"
          item.dianZanNum++
        } else {
          item.isDianZan = "0"
          item.dianZanNum--
        }
      });
    },
    is_self(id) {
      return id == this.$store.getters.userId
    },
    handleStudent(item) {
      this.open = true;
      this.studentList = item.studentName
    },
    // 获取学期
    semester() {
      getAllSemester({}).then((res) => {
        this.semesterlist = res.data;
        this.queryForm.termName = this.semesterlist[0].termName;
        this.getList();
      });
    },
    Scrollbottom () { 
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight - 300) {
        // console.log("滚动到底部了")
        if(this.queryForm.pageNum < this.pageTotal) {
          this.queryForm.pageNum ++
          this.getListCount()
        }
      }
    }
  },
};
</script>


<style scoped>
.el-select >>> .el-input--suffix .el-input__inner {
  padding-left: 5px !important;
}
.el-textarea >>> .el-textarea__inner:focus {
  border: 1px solid #dcdfe6;
}
</style>