// 学生角色查看团体写实记录
import {
  getIndex,
  getTeacherClass,
  getClassStudent,
  addGroupRecord,
  getGroupRecord,
  getDianzan,
  addComments,
  getComments,
  removeComments,
  reply2Cmt
} from "@/api/record/group/group";
let dynamicStuGroup = {
	methods: {
		// 加载更多评论: params与回调
		seeMoreComments(params, cb) {
			getComments(params).then(res => {
				cb && cb(res)
		    })
		},
		// 给2级评论添加评论
		reply2cmt() {
			let params = {
				id: this.cmtMultiGrade.item.id,
				replyToUserId: this.cmtMultiGrade.el[5],
				comments: this.commentContent
			}
			if (this.cmtMultiGrade.grade == 3) {
				params.isMoreOneLevelReply = 1
				params.moreOneLevelReplyToUserName = this.cmtMultiGrade.cmt[2].split('->')[0]
			}
			reply2Cmt(params).then(res => {
				this.commentContent = ''
				let d = res.data.split(',')
				d[3] = this.splitDate(d[3])
				let { item, idx, el, elIdx }  = this.cmtMultiGrade
				// 操作页面list
				this.dynamic[idx].comments[elIdx][6].list.unshift(d)
				// 页面list总数手动 + 1
				this.dynamic[idx].comments[elIdx][6].total ++
				// 后台记录总数 + 1
				this.dynamic[idx].comments[elIdx][7].cmtTotal ++
				this.dynamic[idx].isComments = false
			})
		},
		// 添加回复
		submitReply() {
			if(this.commentContent) {
				this.reply2cmt()
			} else {
			  this.$message.warning('不能提交空内容')
			}
		},
		// 评论
		submitComments(item, idx) {
		  this.$refs["commentsForm"][0].validate((valid) => {
		    if (valid) {
		      if(this.commentContent) {
		        addComments({ id: item.id, comments: this.commentContent }).then(
		          (res) => {
		            let afterRes = this.process1stCommts(item, idx, [], [{
		            	commentsNum: 0,
		            	comments: res.data,
		            	isShowMoreCommentsData: 0
		            }])
		            this.dynamic[idx].comments.unshift(afterRes[0])
		            this.commentContent = ''
					this.dynamic[idx].isComments = false
		          }
		        );
		      }else {
		        this.$message.warning('不能提交空内容')
		      }
		    }
		  });
		},
		// 删除评论: item当前写实记录, idx当前写实记录下标, el: 当前评论, elIdx: 当前评论的下标, grade: 1=评论，2=回复, cmtItem: 当前回复, cmtIdx: 当前回复下标
		removeComment(item, idx, el, elIdx, grade, cmtItem, cmtIdx) {
		  this.$confirm("确定删除评论吗？", "提示", {
		    confirmButtonText: "确定",
		    cancelButtonText: "取消",
		    type: "warning",
		  }).then(()=>{
		    // 默认删除评论
		      let params = {
		    	  id: item.id,
		    	  hkey: el[5]
		      }
		    	if (grade == 2) {
		    	  params.hkey = cmtItem[5]
		    	  params.replyToUserId = el[5]
		      }
		    removeComments(params).then(res => {
		    	// 手动删除页面记录 && 更新总数
		    	if (grade == 1) {
		    		this.dynamic[idx].comments.splice(elIdx, 1)
		    		this.dynamic[idx].commentsNum--
		    	} else if (grade == 2) {
		    		this.dynamic[idx].comments[elIdx][6].list.splice(cmtIdx, 1)
		    		this.dynamic[idx].comments[elIdx][6].total--
		    		this.dynamic[idx].comments[elIdx][7].cmtTotal--
		    	}
		      this.$message.success('已删除评论')
		    })
		  }).catch(()=>{
		    return false
		  })
		},
	}
}
export { dynamicStuGroup }