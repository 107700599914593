import {post} from '@/utils/axios'

export const getIndex = params => post('/shida-user-biz/TbDic/getIndexByParentId',params)

export const getIndexThree = (params) => post('/shida-user-biz/TbIndexType/getRealisticIndexByTwoIndexId',params)

export const getTeacherClass = params => post('/shida-user-center/SysDept/getCurTeacherMangerClass',params)

export const getClassStudent = params => post('/shida-user-center/SysDept/getClassStudent2',params)

export const addGroupRecord = params => post('/shida-user-biz/TbRealisticRecordGroups/saveOrUpdate',params)

export const getGroupRecord = params => post('/shida-user-biz/TbRealisticRecordGroups/getData',params)

export const getDianzan = params => post('/shida-user-biz/TbRealisticRecordGroups/dianZan',params)

export const addComments = params => post('/shida-user-biz/TbRealisticRecordGroups/addComments',params)

export const getComments = params => post('/shida-user-biz/TbRealisticRecordGroups/getComments',params)

export const removeGroupRecord = params => post('/shida-user-biz/TbRealisticRecordGroups/remove',params)

export const removeComments = params => post('/shida-user-biz/TbRealisticRecordGroups/removeComments', params)

export const reply2Cmt = params => post('/shida-user-biz/TbRealisticRecordGroups/replyComments', params)
